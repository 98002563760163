// ==========================================================================
// Plyr default config
// ==========================================================================

const defaults = {
  // Disable
  enabled: true,

  // Custom media title
  title: '',

  // Logging to console
  debug: false,

  // Auto play (if supported)
  autoplay: false,

  // Only allow one media playing at once (vimeo only)
  autopause: true,

  // Allow inline playback on iOS
  playsinline: true,

  // Default time to skip when rewind/fast forward
  seekTime: 10,

  // Default volume
  volume: 1,
  muted: false,

  // Pass a custom duration
  duration: null,

  // Display the media duration on load in the current time position
  // If you have opted to display both duration and currentTime, this is ignored
  displayDuration: true,

  // Invert the current time to be a countdown
  invertTime: true,

  // Clicking the currentTime inverts it's value to show time left rather than elapsed
  toggleInvert: true,

  // Force an aspect ratio
  // The format must be `'w:h'` (e.g. `'16:9'`)
  ratio: null,

  // Click video container to play/pause
  clickToPlay: true,

  // Auto hide the controls
  hideControls: true,

  // Reset to start when playback ended
  resetOnEnd: false,

  // Disable the standard context menu
  disableContextMenu: true,

  // Sprite (for icons)
  loadSprite: true,
  iconPrefix: 'plyr',
  iconUrl: 'https://cdn.plyr.io/3.7.8/plyr.svg',

  // Blank video (used to prevent errors on source change)
  blankVideo: 'https://cdn.plyr.io/static/blank.mp4',

  // Quality default
  quality: {
    default: 576,
    // The options to display in the UI, if available for the source media
    options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240],
    forced: false,
    onChange: null,
  },

  // Set loops
  loop: {
    active: false,
    // start: null,
    // end: null,
  },

  // Speed default and options to display
  speed: {
    selected: 1,
    // The options to display in the UI, if available for the source media (e.g. Vimeo and YouTube only support 0.5x-4x)
    options: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 4],
  },

  // Audio tracks
  audioTrack: {
    options: [],
    onChange: null,
    showUnrecognizedLabel: false,
  },

  // Keyboard shortcut settings
  keyboard: {
    focused: true,
    global: false,
  },

  // Display tooltips
  tooltips: {
    controls: false,
    seek: true,
  },

  // Captions settings
  captions: {
    active: false,
    language: 'auto',
    // Listen to new tracks added after Plyr is initialized.
    // This is needed for streaming captions, but may result in unselectable options
    update: false,
  },

  // Fullscreen settings
  fullscreen: {
    enabled: true, // Allow fullscreen?
    fallback: true, // Fallback using full viewport/window
    iosNative: false, // Use the native fullscreen in iOS (disables custom controls)
    // Selector for the fullscreen container so contextual / non-player content can remain visible in fullscreen mode
    // Non-ancestors of the player element will be ignored
    // container: null, // defaults to the player element
  },

  // Local storage
  storage: {
    enabled: true,
    key: 'plyr',
  },

  // Default controls
  controls: [
    'play-large',
    // 'restart',
    // 'rewind',
    'play',
    // 'fast-forward',
    'progress',
    'current-time',
    // 'duration',
    'mute',
    'volume',
    'captions',
    'settings',
    'pip',
    'airplay',
    // 'download',
    'fullscreen',
  ],
  settings: ['captions', 'quality', 'speed', 'audioTrack'],

  // Localisation
  i18n: {
    restart: 'Restart',
    rewind: 'Rewind {seektime}s',
    play: 'Play',
    pause: 'Pause',
    fastForward: 'Forward {seektime}s',
    seek: 'Seek',
    seekLabel: '{currentTime} of {duration}',
    played: 'Played',
    buffered: 'Buffered',
    currentTime: 'Current time',
    duration: 'Duration',
    volume: 'Volume',
    mute: 'Mute',
    unmute: 'Unmute',
    enableCaptions: 'Enable captions',
    disableCaptions: 'Disable captions',
    download: 'Download',
    enterFullscreen: 'Enter fullscreen',
    exitFullscreen: 'Exit fullscreen',
    frameTitle: 'Player for {title}',
    captions: 'Captions',
    settings: 'Settings',
    pip: 'PIP',
    menuBack: 'Go back to previous menu',
    speed: 'Speed',
    audioTrack: 'Audio Track',
    normal: 'Normal',
    quality: 'Quality',
    loop: 'Loop',
    start: 'Start',
    end: 'End',
    all: 'All',
    reset: 'Reset',
    disabled: 'Disabled',
    enabled: 'Enabled',
    advertisement: 'Ad',
    qualityAuto: 'Auto',
    qualityBadge: {
      2160: '4K',
      1440: 'HD',
      1080: 'HD',
      720: 'HD',
      576: 'SD',
      480: 'SD',
    },
    audioTrackLabel: {
      aa: 'Afar',
      ab: 'Abkhazian',
      ae: 'Avestan',
      af: 'Afrikaans',
      ak: 'Akan',
      am: 'Amharic',
      an: 'Aragonese',
      ar: 'Arabic',
      as: 'Assamese',
      av: 'Avaric',
      ay: 'Aymara',
      az: 'Azerbaijani',
      ba: 'Bashkir',
      be: 'Belarusian',
      bg: 'Bulgarian',
      bh: 'Bihari languages',
      bi: 'Bislama',
      bm: 'Bambara',
      bn: 'Bengali',
      bo: 'Tibetan',
      br: 'Breton',
      bs: 'Bosnian',
      ca: 'Catalan',
      ce: 'Chechen',
      ch: 'Chamorro',
      co: 'Corsican',
      cr: 'Cree',
      cs: 'Czech',
      cu: 'Church Slavic',
      cv: 'Chuvash',
      cy: 'Welsh',
      da: 'Danish',
      de: 'German',
      dv: 'Divehi',
      dz: 'Dzongkha',
      ee: 'Ewe',
      el: 'Greek',
      en: 'English',
      eo: 'Esperanto',
      es: 'Spanish',
      et: 'Estonian',
      eu: 'Basque',
      fa: 'Persian',
      ff: 'Fulah',
      fi: 'Finnish',
      fj: 'Fijian',
      fo: 'Faroese',
      fr: 'French',
      fy: 'Western Frisian',
      ga: 'Irish',
      gd: 'Gaelic',
      gl: 'Galician',
      gn: 'Guarani',
      gu: 'Gujarati',
      gv: 'Manx',
      ha: 'Hausa',
      he: 'Hebrew',
      hi: 'Hindi',
      ho: 'Hiri Motu',
      hr: 'Croatian',
      ht: 'Haitian',
      hu: 'Hungarian',
      hy: 'Armenian',
      hz: 'Herero',
      ia: 'Interlingua',
      id: 'Indonesian',
      ie: 'Interlingue',
      ig: 'Igbo',
      ii: 'Sichuan Yi',
      ik: 'Inupiaq',
      io: 'Ido',
      is: 'Icelandic',
      it: 'Italian',
      iu: 'Inuktitut',
      ja: 'Japanese',
      jv: 'Javanese',
      ka: 'Georgian',
      kg: 'Kongo',
      ki: 'Kikuyu',
      kj: 'Kuanyama',
      kk: 'Kazakh',
      kl: 'Kalaallisut',
      km: 'Khmer',
      kn: 'Kannada',
      ko: 'Korean',
      kr: 'Kanuri',
      ks: 'Kashmiri',
      ku: 'Kurdish',
      kv: 'Komi',
      kw: 'Cornish',
      ky: 'Kirghiz',
      la: 'Latin',
      lb: 'Luxembourgish',
      lg: 'Ganda',
      li: 'Limburgan',
      ln: 'Lingala',
      lo: 'Lao',
      lt: 'Lithuanian',
      lu: 'Luba-Katanga',
      lv: 'Latvian',
      mg: 'Malagasy',
      mh: 'Marshallese',
      mi: 'Maori',
      mk: 'Macedonian',
      ml: 'Malayalam',
      mn: 'Mongolian',
      mr: 'Marathi',
      ms: 'Malay',
      mt: 'Maltese',
      my: 'Burmese',
      na: 'Nauru',
      nb: 'Norwegian Bokmål',
      nd: 'Ndebele, North',
      ne: 'Nepali',
      ng: 'Ndonga',
      nl: 'Dutch',
      nn: 'Norwegian Nynorsk',
      no: 'Norwegian',
      nr: 'Ndebele, South',
      nv: 'Navajo',
      ny: 'Chichewa',
      oc: 'Occitan',
      oj: 'Ojibwa',
      om: 'Oromo',
      or: 'Oriya',
      os: 'Ossetian',
      pa: 'Panjabi',
      pi: 'Pali',
      pl: 'Polish',
      ps: 'Pushto',
      pt: 'Portuguese',
      qu: 'Quechua',
      rm: 'Romansh',
      rn: 'Rundi',
      ro: 'Romanian',
      ru: 'Russian',
      rw: 'Kinyarwanda',
      sa: 'Sanskrit',
      sc: 'Sardinian',
      sd: 'Sindhi',
      se: 'Northern Sami',
      sg: 'Sango',
      si: 'Sinhala',
      sk: 'Slovak',
      sl: 'Slovenian',
      sm: 'Samoan',
      sn: 'Shona',
      so: 'Somali',
      sq: 'Albanian',
      sr: 'Serbian',
      ss: 'Swati',
      st: 'Sotho, Southern',
      su: 'Sundanese',
      sv: 'Swedish',
      sw: 'Swahili',
      ta: 'Tamil',
      te: 'Telugu',
      tg: 'Tajik',
      th: 'Thai',
      ti: 'Tigrinya',
      tk: 'Turkmen',
      tl: 'Tagalog',
      tn: 'Tswana',
      to: 'Tonga (Tonga Islands)',
      tr: 'Turkish',
      ts: 'Tsonga',
      tt: 'Tatar',
      tw: 'Twi',
      ty: 'Tahitian',
      ug: 'Uighur',
      uk: 'Ukrainian',
      ur: 'Urdu',
      uz: 'Uzbek',
      ve: 'Venda',
      vi: 'Vietnamese',
      vo: 'Volapük',
      wa: 'Walloon',
      wo: 'Wolof',
      xh: 'Xhosa',
      yi: 'Yiddish',
      yo: 'Yoruba',
      za: 'Zhuang',
      zh: 'Chinese',
      zu: 'Zulu',
    },
  },

  // URLs
  urls: {
    download: null,
    vimeo: {
      sdk: 'https://player.vimeo.com/api/player.js',
      iframe: 'https://player.vimeo.com/video/{0}?{1}',
      api: 'https://vimeo.com/api/oembed.json?url={0}',
    },
    youtube: {
      sdk: 'https://www.youtube.com/iframe_api',
      api: 'https://noembed.com/embed?url=https://www.youtube.com/watch?v={0}',
    },
    googleIMA: {
      sdk: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js',
    },
  },

  // Custom control listeners
  listeners: {
    seek: null,
    play: null,
    pause: null,
    restart: null,
    rewind: null,
    fastForward: null,
    mute: null,
    volume: null,
    captions: null,
    download: null,
    fullscreen: null,
    pip: null,
    airplay: null,
    speed: null,
    quality: null,
    loop: null,
    language: null,
  },

  // Events to watch and bubble
  events: [
    // Events to watch on HTML5 media elements and bubble
    // https://developer.mozilla.org/en/docs/Web/Guide/Events/Media_events
    'ended',
    'progress',
    'stalled',
    'playing',
    'waiting',
    'canplay',
    'canplaythrough',
    'loadstart',
    'loadeddata',
    'loadedmetadata',
    'timeupdate',
    'volumechange',
    'play',
    'pause',
    'error',
    'seeking',
    'seeked',
    'emptied',
    'ratechange',
    'cuechange',

    // Custom events
    'download',
    'enterfullscreen',
    'exitfullscreen',
    'captionsenabled',
    'captionsdisabled',
    'languagechange',
    'controlshidden',
    'controlsshown',
    'ready',

    // YouTube
    'statechange',

    // Quality
    'qualitychange',
    'audiotrackchange',
    'audiotracklistupdate',
    'audiotracklabelsupdate',

    // Ads
    'adsloaded',
    'adscontentpause',
    'adscontentresume',
    'adstarted',
    'adsmidpoint',
    'adscomplete',
    'adsallcomplete',
    'adsimpression',
    'adsclick',
  ],

  // Selectors
  // Change these to match your template if using custom HTML
  selectors: {
    editable: 'input, textarea, select, [contenteditable]',
    container: '.plyr',
    controls: {
      container: null,
      wrapper: '.plyr__controls',
    },
    labels: '[data-plyr]',
    buttons: {
      play: '[data-plyr="play"]',
      pause: '[data-plyr="pause"]',
      restart: '[data-plyr="restart"]',
      rewind: '[data-plyr="rewind"]',
      fastForward: '[data-plyr="fast-forward"]',
      mute: '[data-plyr="mute"]',
      captions: '[data-plyr="captions"]',
      download: '[data-plyr="download"]',
      fullscreen: '[data-plyr="fullscreen"]',
      pip: '[data-plyr="pip"]',
      airplay: '[data-plyr="airplay"]',
      settings: '[data-plyr="settings"]',
      loop: '[data-plyr="loop"]',
    },
    inputs: {
      seek: '[data-plyr="seek"]',
      volume: '[data-plyr="volume"]',
      speed: '[data-plyr="speed"]',
      language: '[data-plyr="language"]',
      quality: '[data-plyr="quality"]',
      audioTrack: '[data-plyr="audioTrack"]',
    },
    display: {
      currentTime: '.plyr__time--current',
      duration: '.plyr__time--duration',
      buffer: '.plyr__progress__buffer',
      loop: '.plyr__progress__loop', // Used later
      volume: '.plyr__volume--display',
    },
    progress: '.plyr__progress',
    captions: '.plyr__captions',
    caption: '.plyr__caption',
  },

  // Class hooks added to the player in different states
  classNames: {
    type: 'plyr--{0}',
    provider: 'plyr--{0}',
    video: 'plyr__video-wrapper',
    embed: 'plyr__video-embed',
    videoFixedRatio: 'plyr__video-wrapper--fixed-ratio',
    embedContainer: 'plyr__video-embed__container',
    poster: 'plyr__poster',
    posterEnabled: 'plyr__poster-enabled',
    ads: 'plyr__ads',
    control: 'plyr__control',
    controlPressed: 'plyr__control--pressed',
    playing: 'plyr--playing',
    paused: 'plyr--paused',
    stopped: 'plyr--stopped',
    loading: 'plyr--loading',
    hover: 'plyr--hover',
    tooltip: 'plyr__tooltip',
    cues: 'plyr__cues',
    marker: 'plyr__progress__marker',
    hidden: 'plyr__sr-only',
    hideControls: 'plyr--hide-controls',
    isTouch: 'plyr--is-touch',
    uiSupported: 'plyr--full-ui',
    noTransition: 'plyr--no-transition',
    display: {
      time: 'plyr__time',
    },
    menu: {
      value: 'plyr__menu__value',
      badge: 'plyr__badge',
      open: 'plyr--menu-open',
    },
    captions: {
      enabled: 'plyr--captions-enabled',
      active: 'plyr--captions-active',
    },
    fullscreen: {
      enabled: 'plyr--fullscreen-enabled',
      fallback: 'plyr--fullscreen-fallback',
    },
    pip: {
      supported: 'plyr--pip-supported',
      active: 'plyr--pip-active',
    },
    airplay: {
      supported: 'plyr--airplay-supported',
      active: 'plyr--airplay-active',
    },
    previewThumbnails: {
      // Tooltip thumbs
      thumbContainer: 'plyr__preview-thumb',
      thumbContainerShown: 'plyr__preview-thumb--is-shown',
      imageContainer: 'plyr__preview-thumb__image-container',
      timeContainer: 'plyr__preview-thumb__time-container',
      // Scrubbing
      scrubbingContainer: 'plyr__preview-scrubbing',
      scrubbingContainerShown: 'plyr__preview-scrubbing--is-shown',
    },
  },

  // Embed attributes
  attributes: {
    embed: {
      provider: 'data-plyr-provider',
      id: 'data-plyr-embed-id',
      hash: 'data-plyr-embed-hash',
    },
  },

  // Advertisements plugin
  // Register for an account here: http://vi.ai/publisher-video-monetization/?aid=plyrio
  ads: {
    enabled: false,
    publisherId: '',
    tagUrl: '',
  },

  // Preview Thumbnails plugin
  previewThumbnails: {
    enabled: false,
    src: '',
  },

  // Vimeo plugin
  vimeo: {
    byline: false,
    portrait: false,
    title: false,
    speed: true,
    transparent: false,
    // Custom settings from Plyr
    customControls: true,
    referrerPolicy: null, // https://developer.mozilla.org/en-US/docs/Web/API/HTMLIFrameElement/referrerPolicy
    // Whether the owner of the video has a Pro or Business account
    // (which allows us to properly hide controls without CSS hacks, etc)
    premium: false,
  },

  // YouTube plugin
  youtube: {
    rel: 0, // No related vids
    showinfo: 0, // Hide info
    iv_load_policy: 3, // Hide annotations
    modestbranding: 1, // Hide logos as much as possible (they still show one in the corner when paused)
    // Custom settings from Plyr
    customControls: true,
    noCookie: false, // Whether to use an alternative version of YouTube without cookies
  },

  // Media Metadata
  mediaMetadata: {
    title: '',
    artist: '',
    album: '',
    artwork: [],
  },

  // Markers
  markers: {
    enabled: false,
    points: [],
  },
};

export default defaults;
